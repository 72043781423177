import React from 'react';
import { graphql } from 'gatsby';
import Flex from 'styled-flex-component';

import { useTrackedState } from '../../lib/state';

import SubLayout from '../../components/SubLayout';
import Card from '../../components/Card';
import ListItem from '../../components/ListItem';
import Link from '../../components/Link';
import { Img } from '../../components/common/Img.style';

import panc from '../../assets/img/panc.png';

const Mibyo = ({ data: { allMarkdownRemark, allFile } }) => {
  const pancreasArticles = allMarkdownRemark.edges
    .filter(
      edge =>
        !!edge.node.frontmatter.date && !!/^\/pancreas\/mibyo\//.test(edge.node.frontmatter.path)
    )
    .map(({ node: { id, frontmatter: { title, path } } }) => ({
      id,
      title,
      path,
    }));
  const { size } = useTrackedState();
  return (
    <SubLayout topTitle="膵臓の「未病」について">
      <Img src={panc} />
      <div
        css={`
          margin: 20px 0;
        `}
      >
        <Card>
          <Flex column>
            {pancreasArticles.map(({ id, title, path }) => (
              <Flex key={id}>
                <Link to={path}>
                  <ListItem
                    label={title}
                    iconColor="#f0862e"
                    labelSize={(size !== 'sm' && 18) || 14}
                  />
                </Link>
              </Flex>
            ))}
          </Flex>
        </Card>
      </div>
    </SubLayout>
  );
};

export default Mibyo;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/pancreas-articles/" } }
    ) {
      edges {
        node {
          id
          frontmatter {
            date
            title
            path
          }
        }
      }
    }
  }
`;
